import React, { useEffect } from 'react';
import {
    Developer,
    DevelopDev
} from "../../asset/img/index";

interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Develop: React.FC<DevelopProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    개발본부
                </span>
                <img src={Developer} />
                <span className='DevelopName'>
                    개발본부 역량
                </span>

                <div className='Develop'>
                    <span className='Bold'>
                        웹 플랫폼 서비스 개발
                    </span>
                    <span className='Light'>
                        개발부의 웹 플랫폼 개발팀에서는 연구·개발된 핵심 기술들을 웹 플랫폼 서비스 형태로 개발하는 업무를 진행하고 있습니다.<br />
                        다양한 프로젝트 수행 및 다수의 서비스 개발을 진행하였고, 기술적 노하우를 가지고 있는 개발자들의 팀워크가 핵심 역량입니다.
                    </span>
                    <span className='Bold'>
                        API 관련 서비스 개발
                    </span>
                    <span className='Light'>
                        기술 기반 회사의 포체인스(주)의 다양한 솔루션들을 API 형태로 서비스화를 진행하고 있고,<br />
                        이를 개발부에서 담당하여 업무를 진행하고 있습니다.
                    </span>
                </div>

                <div className='DevelopImg'>
                    <span className='TextDevelop'>
                        개발본부 담당역할
                    </span>
                    <img src={DevelopDev} />
                </div>
            </div>
        </div>
    )
}
export default Develop; 