import React, { useEffect } from 'react';
import { PlanBack, DevelopPlan } from "../../asset/img/index";


interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Develop: React.FC<DevelopProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'> 
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    기획 및 마케팅부
                </span>
                <img src={PlanBack} />
                <span className='DevelopName'>
                    기획 및 마케팅부 역량
                </span>

                <div className='Develop'>
                    <span className='Bold'>
                        서비스 기획 업무
                    </span>
                    <span className='Light'>
                        기획 및 마케팅부에서는 포체인스(주)의 다양한 기술을 서비스화하기 위한 서비스 기획 업무를 담당하여 사업화를 진행하고 있습니다.
                    </span>
                    <span className='Bold'>
                        서비스 마케팅 업무
                    </span>
                    <span className='Light'>
                        서비스화가 완료된 기술 및 솔루션을 고객사에게 알리기 위한 마케팅 업무를 진행하여 다양한 사업화에 힘쓰고 있습니다.
                    </span>
                </div>

                <div className='DevelopImg'>
                    <span className='TextDevelop'>
                        기획 및 마케팅부 역할
                    </span>
                    <img src={DevelopPlan} />
                </div>
            </div>
        </div>
    )
}
export default Develop; 