import React, { useEffect } from 'react';
import { DetailRDImg, DevelopRandD } from "../../asset/img/index";
import { Link } from 'react-router-dom';

interface RandDProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const RandD: React.FC<RandDProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    R&D 센터
                </span>
                <img src={DetailRDImg} />
                <span className='DevelopName'>
                    R&D 센터 연구 및 개발 역량
                </span>

                <div className='Develop'>
                    <span className='Bold'>
                        암호 연구에 필요한 정수론(Number Theory) 이론 연구
                    </span>
                    <span className='Light'>
                        포체인스(주) R&D센터에서는 암호 시스템 연구, 개발에 필요한 수학적 이론에 해당하는 정수론 이론 연구를 진행하고 있고,<br />
                        타원곡선 및 보형형식에 대한 이론 연구를 철저하게 진행하고 있습니다.
                    </span>
                    <span className='Bold'>
                        새로운 암호 시스템 구축
                    </span>
                    <span className='Light'>
                        포체인스(주) R&D센터는 이미 개발된 암호 시스템에 대한 연구도 진행하지만, 자사만의 차세대 암호 기술에 집중하여<br />
                        연구를 진행하고 있습니다. 이러한 노하우를 기반으로 다양한 오픈소스 개발 및 연구 지원을 진행하고 있습니다.
                    </span>
                    <span className='Bold'>
                        암호 시스템 성능지표 연구
                    </span>
                    <span className='Light'>
                        개발된 다양한 암호 시스템에 대한 성능지표(처리 속도, 보안 강도, 알고리즘 경량성측면) 연구를 진행하고 있습니다.<br />
                        성능지표 연구에 대한 핵심적인 암호 시스템 영역은 타원곡선암호(ECC), 타원곡선 기반 동형암호(ECHC),<br />
                        아이소제니기반 암호(SIDH기반 암호), 영지식증명(ZKP), 다자간계산(MPC) 등이 있습니다.
                    </span>
                    <span className='Bold'>
                        공격법 및 취약점 분석 연구
                    </span>
                    <span className='Light'>
                        암호 프로토콜별 공격법에 대한 이론적 연구를 진행하여 이미 개발되어 있는 다양함 암호 프로토콜에 대한<br />
                        문제점 및 취약점을 분석하고 이론적 혹은 구현 단계에서 발생할 수 있는 다양한 문제들을 개선하고 있습니다.
                    </span>
                    <span className='Bold'>
                        구현 방법론에 대한 연구
                    </span>
                    <span className='Light'>
                        이론적으로 개발된 다양한 암호 프로토콜을 실제로 구현하기 위한 방법론적 연구를 진행하여 구현 여부에 대한<br />
                        가치 판단과 실제 구현을 같이 진행하고 있습니다. 또한 이미 개발된 구현 모델에 대해서도 취약점 분석을 통해서 더 안전하고<br />
                        가벼운 프로토콜 구현을 진행하고 있습니다.
                    </span>
                    <span className='Bold'>
                        네트워크 구조에 대한 수학적 연구
                    </span>
                    <span className='Light'>
                        암호 시스템의 솔루션화를 위하여 안전한 네트워크 환경에 대한 연구, 개발을 진행하고 있습니다. 암호 프로토콜에 적합한<br />
                        최적의 네트워크 구조를 연구 개발하여 실제 구현 단계에서 암호 시스템의 성능을 극대화할 수 있도록 연구, 개발하고 있습니다.
                    </span>
                    <span className='Bold'>
                        다양한 환경에 적용 연구
                    </span>
                    <span className='Light'>
                        암호 기술이 접목가능한 다양한 환경에 대한 연구 및 실제 적용 방법에 대한 연구를 진행하고 있습니다.<br />
                        대표적인 연구 분야는 블록체인(Blockchain)에 적용하기 위한 연구를 통하여 암호화 기반 블록체인인<br />
                        크립토 블록체인(Crypto Blockchain)을 연구 개발하였고, 그 외에도 다자간 계산(MPC)에 적용하기 위한 연구 등을 진행하였습니다.<br />
                        가장 최근에는 영지식증명(ZKP) 중 zk-SNARKs 모델에 자사의 암호 시스템 ECHC를 적용하는 연구를 진행하여<br />
                        이더리움 환경에 접목하는 연구 개발을 진행하였습니다.
                    </span>
                </div>

                <div className='DevelopImg'>
                    <span className='TextDevelop'>
                        R&D 센터 담당역할
                    </span>
                    <img src={DevelopRandD} />
                    <Link to="/detailDevelop">
                    <button className='SoDetailDevelop'>
                    연구 과정 상세보기 &#62;
                </button>
                </Link>
                </div>
            </div>
        </div>
    )
}
export default RandD; 