import React from "react";
import {
    Mobile2023,
    Mobile2022,
    Mobile2021,
    Mobile2020,
} from "../index";

const MobileHistory = () => {
    return (
        <div className="MobileHissstory">
            <span className="MobileHisText">히스토리</span>
            <div className="HistoryFlex">
            <Mobile2023 />
            <Mobile2022 />
            <Mobile2021 />
            <Mobile2020 />
            </div>
        </div>
    )
}
export default MobileHistory;